import React from "react"
// gatsby 
// styled & custom components
import styled from "styled-components"
import { waitlistCss } from "../../styles/GlobalStyles"
// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from "@material-ui/core/Toolbar"
import Grid from "@material-ui/core/Grid"
// waitlist 
import { Waitlist } from 'waitlistapi'

const useStyles = makeStyles((_) => ({
  button: {
    borderRadius: "3rem",
    backgroundColor: "white",
    margin: "16px 16px 16px auto",
    padding: "6px 15px 6px 15px",
    fontFamily: "Inter Regular",
    textTransform: "none",
  },
  toolbar: {
    width: "100%", 
    margin: "0 auto",
    display: "flex",
    maxWidth: "1440px",
  },
  root: {
    flexGrow: 1
  },
  image: {
    maxWidth: "64px",
    marginTop: "10px",
  }
}))

const LandingPage = () => {

  const classes = useStyles()
  
  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar} color="transparent" elevation={0}>
        <Logo>theperks.xyz</Logo>
      </Toolbar>
      <Grid container direction="column" alignItems="center" justifyContent="center" style={{maxHeight: "50%"}}>
        <Grid item xs={12}>
          <Gradient>This is The Perks:</Gradient>
          <WideHeaderText>
            Your personal, digital valet,
            <br />
            without the hassle.
          </WideHeaderText>
        </Grid>
        <Grid item xs={12}>
          <WideSubtitleText>
            In the age of remote, we help make WFH as comfortable as the office.
          </WideSubtitleText>
        </Grid>
        <Grid item xs={12}>
          <Waitlist api_key={process.env.REACT_APP_WAITLIST_KEY} waitlist_link="https://www.theperks.xyz" />         
          <style>{waitlistCss}</style>
        </Grid>    
      </Grid>
    </div>
  )
}

export default LandingPage

const Gradient = styled.h2`
  text-align: center;
  background-image: linear-gradient(186.32deg, #F99E3D 28.9%, #F9C146 57.51%);
  background-size: 100%;
  margin: 10px 0 15px 0;
  @media (max-width: 767px) {
    margin: 25px 0 15px 0;
  }
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
`

const WideHeaderText = styled.h1`
  text-align: center;
  line-height: 1.2;
  margin: 15px 0 25px 0;
`

const WideSubtitleText = styled.h3`
  text-align: center;
  line-height: 1.5;
  padding-bottom: 30px;
`

const Logo = styled.h4`
  margin-left: 20px;
`