import React from "react"

import Layout from "./components/layout/layout"
import LandingPage from "./components/sections/landingPage"

const App = () => {
  return (
    <Layout>
      <LandingPage />
    </Layout>
  )
}

export default App
